import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  companiesToEnvironmentMap,
  environmentOptions,
  eventTypeOptions,
  publishOptions,
  severityOptions
} from '@app/constants';
import { SelectedCompanyService } from '@src/app/services/selected-company/selected-company.service';
import { environment } from '@src/environments/environment';
import { HttpService } from '@zonar-ui/core';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-publisher-form',
  templateUrl: './publisher-form.component.html',
  styleUrls: ['./publisher-form.component.scss']
})
export class PublisherFormComponent implements OnInit, OnDestroy {
  private _onDestroy$ = new Subject<void>();

  publisherForm: FormGroup;
  environmentOptions = environmentOptions;
  severityOptions = severityOptions;
  publishOptions = publishOptions;
  eventTypeOptions = eventTypeOptions;
  assetOptions = [];
  companyOptions = companiesToEnvironmentMap[0].companyList;
  companiesToEnvironmentMap = companiesToEnvironmentMap;

  assetsLoaded = false;

  companyId$ = this._selectedCompanyService.getCompanyId();

  isLastPublishedEventVisible = false;
  selectedAssetOptions = this.assetOptions;

  hasCompanyBeenSelectedFirstTime = false;

  constructor(
    private fb: FormBuilder,
    private _selectedCompanyService: SelectedCompanyService,
    private _httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.publisherForm = this.fb.group({
      environment: ['qa'],
      severity: [''],
      company_id: [''],
      asset_id: [''],
      division_id: [''],
      publishTo: [''],
      eventType: [''],
      title: [''],
      description: ['']
    });
    this.getAssets().pipe(takeUntil(this._onDestroy$)).subscribe();
  }

  submit() {
    console.log('Publisher Form Value:', this.publisherForm.value);
    this.isLastPublishedEventVisible = true;
  }

  handleEnvChange() {
    this.companyOptions = companiesToEnvironmentMap.find(
      (item) => item.environment === this.publisherForm.value.environment
    ).companyList;
  }

  handleAssetDropdownChange($event) {
    const asset = this.assetOptions.find(
      (asset) => asset.value === $event.value
    );
    this.publisherForm.controls.division_id.setValue(asset.division_id);
  }

  onKey(value) {
    this.selectedAssetOptions = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.assetOptions.filter((option) =>
      option.viewValue.toLowerCase().startsWith(filter)
    );
  }

  getAssets() {
    return this.publisherForm.controls.company_id.valueChanges.pipe(
      map((value) => {
        this.assetsLoaded = false;
        return value;
      }),
      filter((x) => !!x),
      switchMap((companyId) => {
        return this._httpService.get(
          `${environment.coreEntityApiBase.url}/assets?companyId=${companyId}&status=ACTIVE&per_page=1000`
        );
      }),
      map((assets: any) => {
        this.hasCompanyBeenSelectedFirstTime = true;
        this.publisherForm.controls.asset_id.reset();
        this.publisherForm.controls.division_id.reset();
        this.assetOptions = assets.body.map((asset) => {
          return {
            value: asset.id,
            viewValue: asset.name,
            division_id: asset.divisions[0]
          };
        });
        this.selectedAssetOptions = this.assetOptions;
        this.assetsLoaded = true;
      })
    );
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.unsubscribe();
  }
}
