<div class="container">
  <app-page-title>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h1>Event Publisher</h1>
    </div>
  </app-page-title>
  <div class="content">
    <form [formGroup]="publisherForm" (submit)="submit()">
      <div class="input-row">
        <span class="form-field-label-container half-width-input">
          <mat-label>Environment</mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              formControlName="environment"
              (selectionChange)="handleEnvChange()">
              <mat-option
                *ngFor="let environment of environmentOptions"
                [value]="environment.value"
                >{{environment.viewValue}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </span>
        <span class="form-field-label-container half-width-input">
          <mat-label>Severity</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="severity">
              <mat-option
                *ngFor="let severity of severityOptions"
                [value]="severity.value"
                >{{severity.viewValue}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <div class="input-row">
        <span class="form-field-label-container half-width-input">
          <mat-label>Company</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="company_id">
              <mat-option
                *ngFor="let option of companyOptions"
                [value]="option.value"
                >{{option.viewValue}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </span>
        <span class="form-field-label-container half-width-input">
          <mat-label>Asset</mat-label>
          <span *ngIf="assetsLoaded">
            <mat-form-field appearance="outline" class="full-width">
              <mat-select
                formControlName="asset_id"
                (selectionChange)="handleAssetDropdownChange($event)">
                <input
                  placeholder="Search..."
                  matInput
                  type="search"
                  (keyup)="onKey($event.target.value)"
                  class="example-input"
                  style="margin: 10px" />

                <mat-option
                  *ngFor="let option of selectedAssetOptions"
                  [value]="option.value"
                  >{{option.viewValue}}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </span>
          <span *ngIf="!assetsLoaded">
            <mat-spinner
              *ngIf="hasCompanyBeenSelectedFirstTime"
              class="center-item"
              diameter="63"></mat-spinner>
            <p
              *ngIf="!hasCompanyBeenSelectedFirstTime"
              class="select-company-message">
              Please select company to load assets.
            </p>
          </span>
        </span>
      </div>
      <input type="hidden" formControlName="division_id" />
      <div class="input-row">
        <span class="form-field-label-container half-width-input">
          <mat-label>Publish To</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="publishTo">
              <mat-option
                *ngFor="let option of publishOptions"
                [value]="option.value"
                >{{option.viewValue}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </span>
        <span class="form-field-label-container half-width-input">
          <mat-label>Event Type</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="eventType">
              <mat-option
                *ngFor="let option of eventTypeOptions"
                [value]="option.value"
                >{{option.viewValue}}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </span>
      </div>
      <mat-label>Title</mat-label>
      <mat-form-field appearance="outline">
        <input matInput formControlName="title" placeholder="Title" />
      </mat-form-field>
      <mat-label>Description</mat-label>
      <mat-form-field appearance="outline">
        <textarea
          matInput
          formControlName="description"
          placeholder="Description">
        </textarea>
      </mat-form-field>
      <button mat-flat-button color="primary" type="submit">Publish</button>
      <button mat-flat-button color="secondary" type="button">
        Last Published Event
      </button>
      <mat-form-field *ngIf="isLastPublishedEventVisible" appearance="outline">
        <textarea matInput> </textarea>
      </mat-form-field>
    </form>
  </div>
</div>
