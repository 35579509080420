export const environmentOptions = [
  { value: 'qa', viewValue: 'QA' },
  { value: 'prod', viewValue: 'Production' }
];

export const severityOptions = [
  { value: '1', viewValue: 'Critical (1)' },
  { value: '2', viewValue: 'Minor (2)' },
  { value: '3', viewValue: 'Low (3)' }
];

export const publishOptions = [
  { value: 'pubsub', viewValue: 'PubSub' },
  { value: 'firehose', viewValue: 'Firehose' }
];

export const eventTypeOptions = [
  {
    value: 'fault_code',
    viewValue: 'Diagnostic code'
  },
  {
    value: 'evir-defect',
    viewValue: 'EVIR Defect'
  },
  {
    value: 'low_battery',
    viewValue: 'Equipment Battery Level'
  },
  {
    value: 'idle_stop_critical',
    viewValue: 'Idle'
  },
  {
    value: 'missed-inspection',
    viewValue: 'Missing Pre-Trip Inspection'
  },
  {
    value: 'posted_speed',
    viewValue: 'Posted Speed'
  },
  {
    value: 'ev_soc_low',
    viewValue: 'State of Charge (Electric Vehicle)'
  },
  {
    value: 'unauthorized_usage',
    viewValue: 'Unauthorized Usage MM'
  }
];

export const companyOptions = [
  {
    viewValue: 'mbl2021',
    value: '24620407-e4d2-4733-97c4-1c177cb8452c'
  },
  {
    viewValue: 'cont1001',
    value: '78b19d90-a987-4766-9172-101eb3fe70f2'
  }
];

export const companiesToEnvironmentMap = [
  {
    environment: 'qa',
    companyList: [
      {
        viewValue: 'mbl2021 for QA',
        value: '24620407-e4d2-4733-97c4-1c177cb8452c'
      },
      {
        viewValue: 'mbl2020 for QA',
        value: 'bd8c3759-8840-48dc-bf45-8c43f3b1bcab'
      }
    ]
  },
  {
    environment: 'prod',
    companyList: [
      {
        viewValue: 'mbl2021 for Prod',
        value: '24620407-e4d2-4733-97c4-1c177cb8452c'
      },
      {
        viewValue: 'cont1001 for Prod',
        value: '78b19d90-a987-4766-9172-101eb3fe70f2'
      }
    ]
  }
];
